import Vue from "vue";
import "amfe-flexible";
import "animate.css";
import { Pagination, Breadcrumb, BreadcrumbItem,  Icon, Empty, Skeleton, SkeletonItem } from "element-ui";
import App from "./App";
import { router } from "./router";
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Pagination);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});
