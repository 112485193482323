import Vue from "vue";
import Router from "vue-router";
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);

const routes = [
  {
    path: "*",
    redirect: "/index",
  },
  {
    name: "index",
    component: () => import("./view/home"),
    meta: {
      title: "浙江简捷物联科技有限公司",
    },
  },
  {
    name: "carbon",
    component: () => import("./view/carbon"),
    meta: {
      title: "零碳数智园区",
    },
  },
  {
    name: "energy",
    component: () => import("./view/energy"),
    meta: {
      title: "数智能源",
    },
  },
  {
    name: "basis",
    component: () => import("./view/basis"),
    meta: {
      title: "数智能源",
    },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("./view/news/index"),
    meta: {
      title: "新闻中心",
    },
    children: [
      {
        path: "/",
        component: () => import("./view/news/list"),
        name: "news",
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/news/detail/:id",
        component: () => import("./view/news/detail"),
        name: "detail",
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const router = new Router({ mode: "history", routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
